import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";

import INFO from "../data/user";
import myBlogs from "../data/blogs";

import "./styles/readBlog.css";

let BlogStyle = styled.div``;

const ReadBlog = () => {
	const navigate = useNavigate();
	let { slug } = useParams();

	const blog = myBlogs[slug - 1];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [blog]);

	BlogStyle = styled.div`
		${blog().style}
	`;

	return (
		<React.Fragment>
			<Helmet>
				<title>{`${blog().title} | ${INFO.main.title}`}</title>
				<meta name="description" content={blog().description} />
				<meta name="keywords" content={blog().keywords.join(", ")} />
			</Helmet>

			<div className="page-content">
				<NavBar />

				<div className="content-wrapper">
					<div className="read-blog-logo-container">
						<div className="read-blog-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="read-blog-container">
						<div className="read-blog-back">
							<img
								src="../back-button.png"
								alt="back"
								className="read-blog-back-button"
								onClick={() => navigate(-1)}
							/>
						</div>

						<div className="read-blog-wrapper">
							<div className="read-blog-date-container">
								<div className="read-blog-date">
									{blog().date}
								</div>
							</div>

							<div className="title read-blog-title">
								{blog().title}
							</div>

							<div className="read-blog-body">
								<BlogStyle>{blog().body}</BlogStyle>
							</div>
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default ReadBlog;
