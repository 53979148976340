import React from "react";
import assemblyDashboard from "../data/img/assembly-dashboard.png"
import reports1 from "../data/img/reports-1.png"
import reports2 from "../data/img/reports-2.png"
import reports3 from "../data/img/reports-3.png"
import reports4 from "../data/img/reports-4.png"
import receiving1 from "../data/img/receiving-1.png"
import receiving2 from "../data/img/receiving-1.png"

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const blog1Images = [
	assemblyDashboard
]

const blog2Images = [
	reports1,
	reports2,
	reports3,
	reports4
];

const blog3Images = [
	receiving1,
	receiving2
]

function blog_1() {
	return {
		date: "Jan 18 2024",
		title: "Advanced Bill of Materials Report",
		description:
			"Advanced NetSuite Reporting",
		keywords: [
			"NetSuite",
			"SuiteQL",
			"React",
			"Suitescript"
		],
		style: `
				.blog-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="blog-content">
					<div className="paragraph">
						<p>
							Using SuiteQL and react, we can visualize a detailed advanced Bill of Materials report that allows the user to drill down detailed levels. Quickly
							understand all the components and subassemblies that make the overall cost of a parent assembly. The user can view the minimum buildable quantity of
							the parent assembly and expand levels to see which component needs replenishment.
						</p>
						<p>
							<li>Filter & Search</li>
							<li>Export details</li>
							<li>View quantity available, quantity on hand, quantity available</li>
							<li>View unit cost & total cost</li>
						</p>
					</div>
				</div>
				<Slide>
					{blog1Images.map((image, index) => (
						<div key={index}>
							<div style={{ 'backgroundImage': `url(${image})`, height: '500px', width: '100%', backgroundSize: 'cover', backgroundPosition: 'center' }}>
								<span>Slide {index + 1}</span>
							</div>
						</div>
					))}
				</Slide>
			</React.Fragment>
		),
	};
}

function blog_2() {
	return {
		date: "Jan 18 2024",
		title: "Advanced NetSuite Reporting",
		description:
			"Advanced NetSuite Reporting",
		keywords: [
			"NetSuite",
			"SuiteQL",
			"React",
			"Suitescript"
		],
		style: `
				.blog-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="blog-content">
					<div className="paragraph">
						<p>
							Using SuiteQL and react, we can visualize advanced reporting for stakeholders. 
						</p>
						<p>
							<li>Charts & Bar Graphs</li>
							<li>Multiple table joins</li>
						</p>
					</div>
				</div>
				<Slide>
					{blog2Images.map((image, index) => (
						<div key={index}>
							<div style={{ 'backgroundImage': `url(${image})`, height: '500px', width: '100%', backgroundSize: 'cover', backgroundPosition: 'center' }}>
								<span>Slide {index + 1}</span>
							</div>
						</div>
					))}
				</Slide>
			</React.Fragment>
		),
	};
}

function blog_3() {
	return {
		date: "Feb 10 2024",
		title: "Barcode Receiving",
		description:
			"Advanced NetSuite Reporting",
		keywords: [
			"NetSuite",
			"SuiteQL",
			"React",
			"Suitescript"
		],
		style: `
				.blog-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="blog-content">
					<div className="paragraph">
						<p>
							Custom receiving dashboard catered to receiving by scanning barcodes & UPCs.
						</p>
						<p>
							<li>Easy user interface</li>
							<li>Increased accuracy & efficiency</li>
							<li>Carton breakdown functionality</li>
						</p>
					</div>
				</div>
				<Slide>
					{blog3Images.map((image, index) => (
						<div key={index}>
							<div style={{ 'backgroundImage': `url(${image})`, height: '500px', width: '100%', backgroundSize: 'cover', backgroundPosition: 'center' }}>
								<span>Slide {index + 1}</span>
							</div>
						</div>
					))}
				</Slide>
			</React.Fragment>
		),
	};
}

const myBlogs = [blog_1, blog_2, blog_3];

export default myBlogs;
