import netsuiteLogo from '../components/projects/netsuite.png'
// import reactLogo from '../components/projects/react.png'
// import ionicLogo from '../components/projects/ionic.png'

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

const INFO = {
	firstName: "Alyssa",
	lastName: "Jean",
	initials: "ajk", // the example uses first and last, but feel free to use three or more if you like.
	position: "a Full Stack Developer",
	gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
	baseColor: colors[0],
	miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
		{
			emoji: '☕',
			text: 'fueled by coffee'
		},
		{
			emoji: '🌎',
			text: 'based in the  - West Coast'
		},
		{
			emoji: "💼",
			text: "Senior NetSuite Developer"
		},
		{
			emoji: "📧",
			text: "alyssa@alyssajean.io"
		}
	],
	main: {
		title: "Alyssa Jean's Reactfolio",
		name: "Alyssa K.",
		email: "hi@alyssajean.io",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/",
		linkedin: "https://linkedin.com/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Full-stack web developer, and amateur wannabe astronaut.",
		description:
			"I am a fullstack developer with extensive expertise in business systems development. I have experience in building scalable, secure, and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "Alyssa Jean",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	blogs: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Advanced Bill of Materials Reporting",
			description:
				"Quickly understand all the components and subassemblies that make the overall cost of a parent assembly.",
			logo: netsuiteLogo,
			linkText: "View Project",
			link: "/blog/1",
		},

		{
			title: "Advanced NetSuite Reporting",
			description:
				"Provide beautiful graphs & reports for stakeholders surprassing saved searches and reports.",
			logo: netsuiteLogo,
			linkText: "View Project",
			link: "/blog/2",
		},

		{
			title: "Barcode Receiving",
			description:
				"Improve efficiency & accuracy by scanning in UPCs and creating carton breakdowns.",
			logo: netsuiteLogo,
			linkText: "View Project",
			link: "/blog/3",
		},

		{
			title: "Amazon Scraping Tool",
			description:
				`Enter a list of UPCs or ASINs and scrape for a profitability report.`,
			logo: netsuiteLogo,
			linkText: "Blog Coming Soon",
			link: "#",
		},
		// {
		// 	title: "Google Chrome Extensions",
		// 	description:
		// 		"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
		// 	logo: reactLogo,
		// 	linkText: "View Project",
		// 	link: "https://github.com",
		// },
		// {
		// 	title: "NOLA JazzFest App",
		// 	description:
		// 		"Connect with your group and see which stage they are at and plan to go to",
		// 	logo: ionicLogo,
		// 	linkText: "Blog Coming Soon",
		// 	link: "#",
		// },
	],
};

export default INFO;
